<template>
  <div class="featured-collection-wrapper" v-if="isValid && !loading">
    <SfCarousel :settings="carouselSettings">
      <SfCarouselItem
        v-for="(card, index) in featuredCollectionData.data.cards"
        v-if="card.active"
        :key="`collection-carousel-item-${index}`"
        class="collection-carousel-item"
        :style="
          isDesktop
            ? { 'background-image': `url(${card.image.desktop.url})` }
            : ''
        "
        :class="assignTheme(card.theme)"
      >
        <div class="inner-wrapper">
          <nuxt-img
            v-if="isMobile || isTablet"
            class="collection-carousel-item__image"
            :src="card.image.mobile.url"
            format="webp"
            :alt="card.image.alt || `Mobile card ${index}`"
            :width="card.image.mobile.width || 1023"
            :height="card.image.mobile.height || 865"
          />
          <div class="collection-carousel-item__text-wrapper">
            <div class="collection-carousel-item__text-container">
              <div
                class="collection-carousel-item__title"
                :class="assignTheme(card.theme)"
              >
                <span class="collection-carousel-item__text-top-text">
                  {{ card.text.top_text }}
                </span>
                <span
                  class="collection-carousel-item__text-main-text"
                  :style="{
                    'background-color': card.background || 'unset',
                    color: setFontColor(card.background),
                  }"
                >
                  {{ card.text.main_text }}
                </span>
              </div>
              <div
                class="collection-carousel-item__content"
                :class="assignTheme(card.theme)"
              >
                <span class="collection-carousel-item__text-content">
                  {{ card.text.content }}
                </span>
                <CustomCta
                  v-if="Object.keys(card).includes('cta')"
                  class="collection-carousel-item__cta"
                  :theme="card.theme"
                  :link="localePath(card.cta.link)"
                  is-transparent
                >
                  {{ card.cta.label || $t('View our collection') }}
                </CustomCta>
              </div>
            </div>
          </div>
        </div>
      </SfCarouselItem>
    </SfCarousel>
  </div>
  <div v-else class="fc-loader"><LoadingDots /></div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  shallowRef,
} from '@nuxtjs/composition-api';
import { SfCarousel } from '@storefront-ui/vue';
import { CustomCta } from '~/components/General';
import { useWindow } from '~/composables';
import LoadingDots from '~/components/General/LoadingDots.vue';

export default defineComponent({
  name: 'FeaturedCollection',
  components: { CustomCta, SfCarousel, LoadingDots },
  head: {},
  props: {
    featuredCollectionData: {
      type: Object,
      required: true,
    },
    // ToDo: define loading in block fetch (Home.vue) and use it
    // loading: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  setup(props) {
    const { isMobile, isDesktop, isTablet } = useWindow();

    const carouselSettings = {
      type: 'carousel',
      arrows: false,
      dots: false,
      autoplay: 3500,
      animationDuration: 1000,
      rewindDuration: 500,
      hoverpause: true,
      perView: 1,
      breakpoints: {
        1919: {
          perView: 1,
          peek: {
            before: 0,
            after: 0,
          },
        },
        1439: {
          perView: 1,
          peek: {
            before: 0,
            after: 0,
          },
        },
        1199: {
          perView: 1,
          peek: {
            before: 0,
            after: 0,
          },
        },
        1023: {
          perView: 1,
          peek: {
            before: 0,
            after: 0,
          },
        },
        767: {
          perView: 1,
          peek: {
            before: 0,
            after: 0,
          },
        },
        449: {
          perView: 1,
          peek: {
            before: 0,
            after: 0,
          },
        },
      },
    };

    const assignTheme = (theme) => {
      return theme === 'dark' ? 'dark-theme' : 'light-theme';
    };

    // ToDo: Remove fake loading and use prop defined in fetch
    const loading = shallowRef(false);
    onMounted(() => {
      loading.value = false;
    });

    const isValid = computed(() => {
      return !!(
        props.featuredCollectionData?.data &&
        props.featuredCollectionData?.active
      );
    });

    const setFontColor = (backGroundColor) =>
      backGroundColor ? 'white' : 'inherit';

    return {
      setFontColor,
      carouselSettings,
      isMobile,
      isTablet,
      isDesktop,
      assignTheme,
      loading,
      isValid,
    };
  },
});
</script>

<style lang="scss" scoped>
$classic-margin-right: 8.438rem;
.featured-collection-wrapper {
  height: auto;
  max-height: unset;
  margin-bottom: 5rem;
  .collection-carousel-item {
    background-size: cover;
    &.light-theme {
      background-color: #ebebeb;
    }
    &.dark-theme {
      background-color: black;
    }
    &__image {
      max-width: 100vw;
      height: auto;
      object-fit: contain;
    }

    .collection-carousel-item__text-wrapper {
      height: inherit;
      max-height: inherit;
      .collection-carousel-item__text-container {
        display: flex;
        flex-direction: column;
        .collection-carousel-item {
          &__text-top-text,
          &__text-main-text,
          &__text-content {
            display: block;
          }
          &__text-top-text {
            @include paragraph-m;
          }
          &__text-main-text {
            @include mobile-h3;
            word-break: break-word;
          }
          &__text-content {
            @include paragraph-m;
            margin-bottom: 1.25rem;
          }
          &__title {
            padding: 1.25rem 1.25rem 0;
            &.light-theme {
              color: black;
            }
            &.dark-theme {
              color: white;
            }
          }
          &__content {
            max-width: 20rem;
            margin: 1.875rem 1.25rem 3.75rem;
            @include from-desktop-min {
              margin: 1.875rem $classic-margin-right 1.25rem 3.75rem;
            }
            align-self: flex-end;
            &.light-theme {
              color: black;
            }
            &.dark-theme {
              color: white;
            }
          }
        }
      }
    }
  }
}

.fc-loader {
  width: 100vw;
  height: 37.5rem;
  min-height: 56.15vw;
  background-color: #ebebeb;
  @include flex-center;
}

@include from-desktop-min {
  .featured-collection-wrapper {
    height: clamp(56.15vw, auto, 50.5rem);
    @include desktop-boxed;
    .collection-carousel-item {
      background: no-repeat left/contain;
      height: inherit;
      max-height: inherit;
      .collection-carousel-item__text-wrapper {
        height: inherit;
        max-height: inherit;
        display: flex;
        justify-content: flex-end;
        .collection-carousel-item__text-container {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .collection-carousel-item {
            &__text-top-text {
              margin-right: calc(20rem + $classic-margin-right);
              @include paragraph-m;
            }
            &__text-main-text {
              @include mobile-h1;
              text-align: right;
              word-break: unset;
              padding-right: $classic-margin-right;
              width: 100vw;
            }
            &__text-content {
              @include paragraph-m;
              margin-top: 1.25rem;
              margin-bottom: 1.25rem;
              display: block;
            }
            &__title {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              padding-top: min(calc(28.075vw - 3.875rem), 21.375rem);
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1100px) {
  .featured-collection-wrapper {
    .collection-carousel-item {
      .collection-carousel-item__text-wrapper {
        .collection-carousel-item__text-container {
          .collection-carousel-item {
            &__title {
              padding-top: min(calc(28.075vw - 6.6875rem), 24.8125rem);
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.featured-collection-wrapper {
  .sf-carousel {
    max-height: inherit;
    height: inherit;
    --carousel-controls-display: none;
    .sf-carousel__wrapper {
      max-height: inherit;
      height: inherit;
      @include desktop-boxed;
      width: 100vw;
      padding: 0;
      margin: 0;
      overflow: hidden;
      .glide.glide--carousel {
        max-height: inherit;
        height: inherit;
        .glide__track {
          max-height: inherit;
          height: inherit;
          .glide__slides.sf-carousel__slides {
            max-height: inherit;
            height: inherit;
            .sf-carousel-item.glide__slide {
              max-height: inherit;
              height: inherit;
              @include desktop-boxed;
              .carousel-item-content {
                max-height: inherit;
                height: inherit;
              }
            }
          }
        }
      }
    }
  }
}
</style>
